.articles .art h1
{
    color: rgb(249, 116, 0) !important;
}
.articles .art h1, h2
{
    color:white;
}
.articles .art p
{
    color: white;
    font-size: 20px;
    margin-bottom: 4vh;
}
.articles .art img
{
    cursor: pointer;
}
.articles .art
{
    justify-content: center;
    align-items: center;
    margin: 10vh 1vw;
}
.articles .art img
{
    width: 70%;
    height: 70vh;
}
.articles
{
    padding-top:100px ;
    width: 80%;
    margin: 2px auto;
}

