
.about
{
    padding-top: 70px;
    padding-bottom: 50px;
    /* background-color: white; */
    color: aliceblue;
}
.about::before
{
    content: '';
    background-image: url('https://cdn.pixabay.com/photo/2018/07/18/17/10/cosmos-3546765_960_720.jpg');
    position: absolute;
    height: 105%;
    width: 100%;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: .4;
    z-index: -2;
}
.header
{
    border-bottom: 1px grey solid;
}
.about .aboutInner
{
    display: flex;
}
.about  h1
{
    color:white;
    text-align: center;
    font-size: 50px;
}
.about .aboutInner .box
{
    margin: 2px 120px 2px 84px;
    padding: 2px 2px;
}
.about .aboutInner .img img
{

    height: 100vhvh;
    width: 38vw;
    margin-top: -90px;
}
.cources .ccont .img {
    margin: 90px 2px 2px 1px !important;
}
.about .aboutInner .box p
{
    font-size: 18px;
    font-family: 'Baloo Bhai 2', cursive;
}
body
{
    scroll-behavior: smooth;
}