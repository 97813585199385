.intro
{
    padding-top: 100px;
    color: white;
    width: 80vw;
    margin: 0px auto;
}
.intro img
{
    width: 80%;
    height: 60vh;
}
.intro .sign img {
    height: 80px;
    width: 100px;
    margin: 3px 1px 1px 20px;
    padding: 2px 2px 2px 2px;
    border-radius: 69px;
    /* border: 2px solid white; */
}
.intro .sign h3
{
    display: inline;
} 
.intro .sign .title
{
    height: 80px;
    display: flex;
}
.intro .sign p
{
    margin-top: 0px;
} 
.intro .sign 
{
    display: inline-block;
    width: 40%;
    margin: 2px 2px;
    padding: 2px 2px;
}
/* .intro .sign img
{
    width: 10vw;
    height: 10vh;
    margin: 2px 2px;
    padding: 2px 2px;
} */
.intro p
{
    font-size: 20px;
}