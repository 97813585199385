body{
    scroll-behavior: smooth;
    overflow-y: none;
    overflow-x: none;
    font-family: 'Noto Sans', sans-serif;
    box-sizing: border-box;
}
.header .nav ul
{
    display: flex;
    margin: 2px 3px 3px 8rem;
    padding: 2px 2px;
}
.header  
{
    background-color: white;
    position: fixed;
    display: flex;
    box-sizing: border-box;
    margin: 0px;
    width: 100vw;
    z-index: 1000;
}
.header .nav .title h1
{
    margin: 0px;
    font-size: 35px;
    font-weight: 600;
    color: #29003A;
    font-family: 'Rajdhani', sans-serif;
    
}
.header .nav .title
{
    margin: auto 25px;
    margin-left: 0px;
}
.header .nav
{
    /* background-color: #29003A; */
    display: flex;
}
.header .nav ul li
{
    list-style: none;
    margin: 12px 2px;
    padding: 2px 2px;
}
.header .nav ul li a
{
    margin: 1px 1px;
    padding: 2px 17px;
    border-bottom: 1px white solid;
    border-radius: 20px;
    font-size: 20px;
    font-family: 'Baloo Bhai 2', cursive;
    font-weight: 600;
    text-decoration: none;
    color: #29003A;
}
.header .nav ul li .active
{

    color: #29003A;
    /* text-decoration: underline; */
    border-bottom: 2px #29003A solid;
}
.header .nav ul li a:hover
{
    background-color: #29003A;
    color: aliceblue;
}
body
{
    /* background-color:  #29003A; */
    /* background-color:  #180121; */
    background-color: black;
    margin: 0px;
    overflow-x: none;
}
/* .container
{
    display: flex;
} */
.container
{
    padding-top: 71px;
    display: flex;
    /* width: 100vh; */
    /* height: 1022px; */
    flex-direction: column;

    
}
.container1{
    display: flex;
}
.container1 img
{
    position: absolute;
    left: 0px;
    right: 0px;
}
.container1 .sky
{
    left: 0px;
    top: auto;
    height: 700px;
    width: 100vw;
    z-index: -2;
}
.container1 #sky
{
    display: none;
}
.container1 .fullMoon
{
    left: 78vw;
    top: 200px;
    /* margin-left: 1100px; */
    height: 173px;
    width: 278px;
    z-index: 3;
}
/* .nav .title h1{
    position: absolute;
    top:0px;
    left: 0px;
    z-index: 14;
} */
.nav .planet
{
    /* position: absolute; */
    top:20px;
    left: 180px;
    height: 50px;
    z-index: 12;
    margin-left: 10px;
    margin-top: 8px;
}
.container1 .mountain
{
    bottom: -88px;
    height: 117vh;
    width: 100vw;
    z-index: 6;
}
.container1 .lightning
{
    /* top: -50px;
    right: 100px; */
    margin-left: 1100px;
    margin-top: 60px;
    /* margin-right: 200px; */
    height: 100px;
    width: 200px;
    z-index: 2;
}
.container1 
{
    /* display: flex; */
    /* background-image: url('first.jpg') ;
    min-height: 90vh;
    min-width: 600px;
    color: #180121;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
    height: 100vh;
}

.container .container1 .box .a a:hover
{
        background-color: #3d0b54;
        color: white;
}
.container .container2 .box .a a:hover
{
        background-color: #3d0b54;
        color: white;
}
.container .container1 .box h3
{
    color: #ecdbf4;
    font-size: 24px;
    margin: 0px;
}
.container .container1 .box h1
{
    color: white;
    margin-bottom: 14px;
    font-size: 45px;
}
.container .container1 .box:hover
{
    z-index: 7;
}
.container .container1 .box
{
    /* display: inline-block; */
    position: absolute;
    margin: 31px 42px 2px 154px;
    padding: 2px 224px 2px 2px;
    z-index: 9;
}
.container .container1 .box .a
{
    margin: 35px 1px 1px 1px;
    
}
.container .container1 .box .a a
{
    border: 1px white solid;
    border-radius: 25px;
    padding: 9px 34px;
     font-family: 'Baloo Bhai 2', cursive;
     font-weight: 400;
    text-decoration: none;
    color: #29003A;
    background-color: white;
}
/* .container .container1 .img
{   
   margin-right: 0px;
   margin: 31px 110px 2px 2px;
} */

.container2
{
    margin-top: 0px;
    display: flex;
    /* background-image: url('earth.jpg'); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
}
.container .container2 .box h3
{
    color: #ecdbf4;
    font-size: 24px;
    margin: 0px;
}
.container .container2 .box h1
{
    color: white;
    margin-bottom: 14px;
    font-size: 45px;
    margin-top: 0px;
}
.container .container2 .box {
    display: inline-block;
    margin: 123px 42px 2px 226px;
    padding: 1px 8px 2px 2px;
    width: 51%;
}
.container .container2 .box .a
{
    margin: 35px 1px 1px 1px;
    
}
.container .container2 .box .a a
{
    border: 1px white solid;
    border-radius: 25px;
    padding: 9px 34px;
     font-family: 'Baloo Bhai 2', cursive;
     font-weight: 400;
    text-decoration: none;
    color: #29003A;
    background-color: white;
}
.container .container2 .img
{
    margin: 30px 3px 4px 40px;
}
.video iframe
{
    width: 88vw;
    height: 42rem;
    margin: 50px 24px;
}
/* width: 88vw;
height: 42rem;
margin: 49px 23px; */
.video h1
{
    text-align: center;
    color: white;
    margin-bottom: 14px;
    font-size: 45px;
}
.video h3
{
    text-align: center;
    color: #ecdbf4;
    font-size: 24px;
    margin: 0px;
}
.video
{
    margin-top: 150px;
    display: flex;
    flex-direction: column;
}
.footer
{
    /* border-top: 1px solid white; */
    margin: 20px 10px;
}
.summury
{
    display: flex;
    /* background-color: white; */
   padding: 27px 79px;
   padding-top: 40px;
}
.summury::before
{
    content: "";
    background-image: url('https://cdn.pixabay.com/photo/2018/02/26/16/58/astronomy-3183482__340.jpg');
    position: absolute;
    background-attachment: fixed;
    /* top: 0px; */
    left: 0px;
    height: 70vh;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: .5;
    z-index: -2;

}
.summury .box h1
{
    margin-left: 113px;
    color: #ecdbf4;
    font-family: 'Baloo Bhai 2', cursive;
    margin-bottom: 14px;
    font-size: 45px;
}
.summury .box ul li{
    color: #ecdbf4;
    font-size: 25px;
}
.summury .box
{
    /* width: 33%; */
    padding: 16px -7px;
    padding-bottom: 40px;
    color: #ecdbf4;
    /* display: flex; */
    /* padding-top: 91px; */
    margin: 10px 60px;
    /* border-bottom: 2px solid white; */
}
.summury .box img
{
        margin: 9px 9px;
        width: 80%;
        height: 261px;
}
.video::before
{
    content: '';
    position: absolute;
    top: 700px;
    height: 150px;
    width: 100%;
    background-image: linear-gradient(transparent,black,transparent);
    /* background-color:black; */
    z-index: 100;
}

.nav::after
{
    content: '';
    position: absolute;
    bottom: 0px;
    height: 20px;
    width: 100%;
    /* background-color: #29003A; */
    background-image: linear-gradient(transparent,transparent,black);
    z-index: 1000000;
}
.pred img
{
    height: 50px;
    width: 50px;
    margin-top: 7px;
    cursor: pointer;
}
/* .pred
{
    position: absolute;
    right: 10px;
    top: 200px;
} */
#float
{
    position: absolute;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    /* top: 30px; */
    margin-top: 10px;
    z-index: 9999999;
    border-top: 1px black solid;
    display: none;
}
.header .nav ul li  .e2
{
    cursor: pointer;
}
.header .nav ul li  .f
{

    font-size: 15px;
    display: block;
    color:black;
    text-align: center;
    background-color:white;
    border: 1px black solid;
    border-radius: 0px;
    margin-top: -15px;
    transition: all;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
}
#float:hover .f
{
    margin-top: 0px;
    cursor: pointer;
}

@media (max-width:1448px){
    .header .nav ul
{
    margin: 2px 3px 3px 20rem;
}
.header .nav .title h1
{
    font-size: 30px;
}
.header .nav ul li a
{
    font-size: 15px;
}
.header .nav ul li .f
{
    font-size: 12px;
}
.header .nav ul {
    margin: 2px 3px 3px 12rem;
}
.header .nav ul {
    margin: 2px 3px 3px 8rem;
}

}
@media ( max-width:1268px)  {
    .header .nav ul
{
    margin: 2px 3px 3px 10rem;
}
.header .nav .title h1
{
    font-size: 25px;
}
.header .nav ul li a
{
    font-size: 10px;
}
.header .nav ul li .f
{
    font-size: 8px;
}
.summury .box
{
    border-bottom: 1px solid white;
}
.summury
{
    flex-direction: column;
    padding: 0px;
}
.summury .box ul li{
    font-size: 20px;

}
.summury .box h1
{
    font-size: 35px;
}
.container .container1 .box h1
{
    font-size: 30px;
}
.container .container1 .box h3
{
    font-size: 17px;
}
.about .aboutInner .box {
    margin: 2px 62px 2px 30px !important;
}

.cources .ccont .img {
    margin: 2px 2px 2px 1px;
}
.cources .ccont2 .img {
    margin: 2px 2px 2px 1px;
}
.cources {
    padding: 40px !important;
    padding-bottom: 10px !important;
}
.cources .ccont .box {
    margin: 32px 131px 2px 72px;
}
.cources .ccont2 .box {
    margin: 32px 131px 2px 72px;
}
.summury .box{
    width: 50%;
    margin: 20px auto;
}
body
{
    background-position: initial;
}
}
@media ( max-width:980px) {

    .header .nav ul
{
    margin: 2px 3px 3px 0rem;
}
.header .nav ul li a
{
    font-size: 10px;
}
.header .nav 
{
    flex-direction: column;
}
.video iframe
{
    height: 60vh;
}
.container1 .fullMoon
{
    left: 62vw;
}
.container .container1 .box
{
    /* display: inline-block; */
    margin: 31px 42px 2px 42px;
    padding: 2px 8px 2px 2px;
}
.container
{
    padding-top: 121px;
}
.about {
    padding-top: 113px !important;
}
.about .aboutInner .img img
{

    height: 422px;
    width: 38vw;
}
.cources .ccont .box {
    margin: 32px 28px 2px 7px !important;
}
.cources .ccont2 .box {
    margin: 32px 28px 2px 7px;
}
.cources .ccont .box h1 {
    font-size: 34px;
}
.cources .ccont2 .box h1 {
    font-size: 34px;
}
ul li {
    font-size: 20px;
}
.cources .ccont .img img
{
    margin-top: 60px;
}
.cources .ccont2 .img img
{
    margin-top: 60px;
}
.about .aboutInner
{
    display: flex;
    flex-direction: column-reverse;
}
.about .aboutInner .img img {
    height: 422px;
    width: 90vw;
    margin-left: 40px;
}

.summury .box{
    width: 61%;
    margin: 20px auto;
}
/* .books .card::after {
    margin-left: -50px;
    margin-top: -13px;
}
.books .card::after {
    margin-left: -58px;
    margin-top: -16px;
    width: 40%;
}
.books .card {
    height: 45vh;
    margin: 8px 5%;
    width: 25vw;
    margin-top: 80px;
}
.book_title
{
    padding-top: 130px;
} */
/* .books 
{
    padding-top: 120px;
} */

.books {
    padding-top: 98px;
}
.books h1 {
    font-size: 29px;
}
body
{
    background-size: 133%;
}
.books .show a {
    border-radius: 15px;
    padding: 1px 8px;
    font-size: 17px;
}
.books .show {
    /* width: 15vw;
    position: absolute; */
    top: 9.2rem;
    /* left: 2rem;
    transition: all 2s 1s; */
}
}
@media ( max-width:700px) {
    
    .container1 .fullMoon
{
    left: 40vw;
}
.container .container1 .box h1
{
    font-size: 25px;
}
.container .container1 .box h3
{
    font-size: 13px;
}
.container .container1 .box .a a
{
    font-size: 15px;
}
.about h1 {
    font-size: 28px;
}
.about .aboutInner .box p {
    font-size: 11px;

}
h2 {
    font-size: 1.3em;
}
.cources .ccont .img img
{
    width: 40vw;
}
.cources .ccont2 .img img
{
    width: 40vw;
}
.cources
{
    margin-left: 0px;
    padding-left: 15px !important;
}
.cources .ccont .box h1 {
    font-size: 25px;
}
.cources .ccont .box ul li {
    font-size: 12px;
}
.cources .ccont2 .box ul li {
    font-size: 20px;
}
.cources .ccont .box {
    margin: 47px -27px 2px 7px;
}
.cources .ccont2 .box {
    margin: 47px -27px 2px 7px !important;
}
.cources .box h3 {
    font-size: 18px;
}
.cources .p2 .box h3
{
    margin: 0px;
    margin-left: 112px;
}
.cources .ccont .box h1 {
    font-size: 15px;
}

.cources .box a {
    font-size: 12px;
}
.cources .box .a {
    margin: 10px 107px;
}
.cources .box .free {
    width: 235px;
}
.cources .box h3 {
    font-size: 25px;
    margin: 20px 61px;
    width: 241px;
    text-align: center;
}
.cources .ccont2 .img img {
    width: 40vw;
}
.cources .box h3 {
    margin: 0px;
    margin-bottom: 10px;
}
.cources .box .a {
    margin: 1px 2px;
    margin-top:30px ;
}
.cources .p2 .box .a{
    width: 338px;
    margin-left: 154px;
    margin: 0px;
    margin-left: 109px;
    margin-top: 20px;
}
.contact h1 {
    text-align: center;
    color: white;
    font-size: 28px;
    margin: 79px auto;
    margin-bottom: 20px;
}
.contact h2 {
    text-align: center;
    color: white;
    font-size: 16px;
    margin: 2px auto;
}
.contact {
    width: 88% !important;
    margin: 0px auto !important;
    padding-top: 60px !important;
}
.about .aboutInner .img img {
    height: 422px;
    width: 90vw;
    margin-left: 40px;
}
.about .aboutInner .box {
    margin: 0px 12px 2px 30px !important;
}
.footer h3
{
    font-size: 12px;
}
.contact {
    padding-top: 148px;
}
.contact
{
    height: 76vh !important;
}
.contact::before {
    height: 68vh;
}
.intro .sign 
{
    display: inline-block;
    width: 100%;
    margin: 2px 2px;
    padding: 2px 2px;
}
.intro img
{
    width: 100%;
}
.header .nav ul li {
    margin: 12px -5px;
}
.articles
{
    padding-top: 130px;
}
.articles .art h1
{
    font-size: 20px;
}
.header .nav ul li a {
    margin: 1px 0px;
    padding: 2px 14px;
}
.articles .art img {
    width: 100%;
}
.intro .sign h1
{
   font-size: 20px;
} 
.intro p {
    font-size: 17px;
}
.intro {
    padding-top: 130px;
}
.articles .art p {
    font-size: 17px;
}
.summury .box{
    width: 81%;
    margin: 20px auto;
}
.sec2 {
    margin: 95px 2px 2px 3vw;
}
.books h1 {
    font-size: 20px;
}
body
{
    background-size:cover;
    background-position-x:-118px ;
    background-position-y: 60px;
}
.books .card {
    width: 1.1rem;
}
.books .show {
    width: 15vw;
    position: absolute;
    top: 7.2rem;
    left: 2rem;
    /* transition: all 2s 1s; */
}
.books .show a {
    border-radius: 10px;
    padding: 1px 5px;
    font-size: 12px;
}
.container1 #sky
{
    display: block;
}
.container1 .sky
{
    display: none;
}
.footer h3
{
    font-size: 10px;
}
.summury .box ul li {
    font-size: 10px;
}
.summury .box h1 {
    font-size: 20px;
}
.articles .art h1 {
    font-size: 10px;
}
.articles .art p {
    font-size: 9px;
}
h2 {
    font-size: 12px;
}
}
@media ( max-width:470px) {
    .container1 .fullMoon {
        left: 33vw;
    }
    
    .container .container1 .box h1 {
        font-size: 21px;
    }
    .container .container1 .box h3 {
        font-size: 11px;
    }
    .container .container1 .box .a a {
        font-size: 11px;
    }
    .about .aboutInner .img img {
        height: 422px;
        width: 90vw;
        margin-left: 40px;
    }
    .cources .box ul {
        padding-left: 22px;
        width: 234px;
        margin-bottom: 35px;
        margin-top: 10px;
    }
    .cources {
        margin-left: 0px;
        padding-left: 0px;
    }
    .cources .box h3 {
        font-size: 16px;
        width: 210px;
    }
    .cources .box a {
        font-size: 10px;
    }
    .cources .box .free {
        width: 225px;
        margin-top: 19px;
    }
    .cources .ccont2 .box {
        width: 387px;
    }
    .cources .p2 .box .a {
        width: 250px;
    }
    .video {
        margin-top: -50px;
    }
    .video h1 {
        font-size: 28px;
    }
    .video h3 {
        font-size: 18px;
    }
    .contact {
        padding-top: 148px;
    }
    .contact {
        margin: 0px auto;
    }
    .books .card .title
    {
        height: 40px;
    }
    /* .books .card
    {
        height: 500px;
        width: 89vw;
    } */
    .books .card a
    {
       
        margin-left: 2vw;
    }
    .header .nav ul li a {
        padding: 2px 8px;
    }
    .intro p {
        font-size: 17px;
    }
    .articles .art p {
        font-size: 17px;
    }
    .header .nav ul li {
        margin: 12px -1px;
        padding: 2px 0px;
    }
    /* .books .card::after {
        margin-left: -22px;
        margin-top: -11px;
        width: 48%;
    }
    .books .card {
        width: 37vw;
    } */
    .books .show {
        /* background-color: rgb(37, 34, 0); */
        /* border: 2px black solid; */
        color: white;
        /* height: 100%; */
        /* height: 66vh; */
        width: 27vw;
        position: absolute;
        top: 7rem;
        left: 2rem;
        /* transition: all 2s 1s; */
    }
    .books .show a {
        border-radius: 10px;
        padding: 1px 5px;
        font-size: 12px;
    }
    .sec2 {
        margin: 105px 2px 2px 3vw;
    }
    body
    {
        background-size: 242%;
        background-position-x:-158px ;
        background-position-y: 20px;
    }
    .nav .planet {
        height: 34px;
    }
    
    .header .nav ul li a {
        letter-spacing: -0.8px;
    }
    .container1 .fullMoon {
    
        height: 110px;
        width: 186px;
    
    }
    .container1 .sky {
        left: 0px;
        top: auto;
        height: 350px;
        width: 100vw;
        z-index: -2;
    }
    
    .container1 {
        /* display: flex; */
        height: 72vh;
    }
    
    .video::before {
    
        top: 444px;
    
        z-index: -1;
    }
    .container {
        padding-top: 106px;
    }
    
    .video iframe {
        margin: 8px 23px;
    }
    .cources .ccont .box {
        margin: 47px -52px 2px 7px;
    }
    
    .intro img {
        height: 30vh;
    }
    .articles .art img {
        width: 70%;
        height: 30vh;
    }
    .header .nav ul #f1 #float .f
    {
        margin-top: -1px;
        transition: none;
    }
    .footer h3
    {
        font-size: 10px;
    }
    .summury .box ul li {
        font-size: 10px;
    }
    .summury .box h1 {
        font-size: 20px;
    }
    .summury .box img {
        margin: 9px 9px;
        width: 69%;
        height: 159px;
    }
    .footer {
        /* border-top: 1px solid white; */
        margin: -10px 6px;
    }
    .cources  .box h3
    {
        width: 167px;
    }
    .cources .box a
    {
        padding: 9px 17px;
    }
    .cources .box .free {
        width: 172px;
    }
    .cources .ccont .box {
        width: 236px;
    }
    .cources .ccont2 .box {
        width: 325px;
    }
    .cources .ccont2 {
        display: flex;
        margin-top: 6px;
    }
    .articles .art p {
        font-size: 9px;
    }
    .intro h1{
        font-size: 10px;
    }
    .intro {
        font-size: 9px;
    }
    .intro p {
        font-size: 9px;
    }
    
}
@media ( min-width:2000px) {
    .summury
    {
        justify-content: space-between;
    }
    
}
    