.weekly h1
{
    color: white;
}
.weekly
{
    display: flex;
    padding-top: 10vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.weekly .box:hover .left h1
{
    color: rgba(214, 158, 80, 0.628);
}
.weekly .box:hover
{
    border: 1px solid rgb(247, 171, 7);
    box-shadow: 1px 1px 1px 1px rgb(247, 171, 7);
}
.weekly .box
{
    display: flex;
    width: 90vw;
    height: 83vh;
    padding: 3vh;
    border: 1px solid grey;
    margin: 2vh;
}
.weekly .box .right h3
{
    margin-top: 10px;
}
.weekly .box .right
{
    width: 48vw;
    color: white;
    padding: 10vh;
}
.weekly .box .left h1
{
    color: white;
    padding-bottom: 10px;
}
.weekly .box .left
{
    width: 50%;
    /* padding: 10px; */
    width: 50%;
}
.weekly .box .left img
{
    width: 100%;
    height: 75%;
}
@media (max-width:1000px) {
    .weekly .box
    {
        flex-direction: column;
        height: 100%;
    }
    .weekly .box .left {
        width: 100%;
    }
}
@media (max-width:800px) {
    .weekly
    {
        padding-top: 18vh;
    }
    .weekly .box
    {
        flex-direction: column;
        height: 100%;
    }
    .weekly .box .left {
        width: 100%;
    }
    .weekly .box .right {
        width: 100vw;
        color: white;
        padding: 10vh;
        height: 35vh;
        overflow-y: scroll;
    }
}